<template>
  <div class="taobao-auth">
    <div class="title">
      淘客授权
    </div>

    <!-- 授权说明 -->
    <auth-desc />

    <!-- 授权列表 -->
    <auth-list />
  </div>
</template>

<script>
export default {
  name: 'tao-bao-auth',
  components: {
    'auth-desc': () => import('./AuthDesc'),
    'auth-list': () => import('./AuthList')
  }
}
</script>

<style lang="less">
.taobao-auth {
  padding: 1rem;
  .title {
    margin-top: 0px;
    padding-bottom: 1rem;
    border-bottom: 1px #CCCCCC solid;
  }

}
</style>